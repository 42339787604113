import React, { ElementType } from 'react'
import './blocks.css';

type Props = {
  title: string;
  image: string;
  content: React.ReactNode // A list of sections containing SingleHighlight
}

/**
 * @brief Building block contains two horizontal parts, one with an image and one with text and buttons
 * @param props 
 * @returns HTML
 */
const Landing = (props: Props) => {
  return (
    <div className='blocks__landing-image' style={{backgroundImage: `url(${props.image})`}}>
        <div className='blocks__landing-overlay'>
          <div className='blocks__landing-contents blocks__container'>
            <h1 className='h1 text-center text-white'> {props.title} </h1>
            {props.content}
          </div>
        </div>
    </div>
  )
}

export default Landing;