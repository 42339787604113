import React from 'react'
import { NavLink } from "react-router-dom";
import './buttons.css';
import { IconType } from 'react-icons/lib';

type Props = {
    color: string;
    text: string;
    url: string;
    icon: IconType;
}

const ButtonIcon = (props: Props) => {

    return (
        <NavLink 
            className={'button__all button__icon b1'}
            style={{ color: props.color, borderColor: props.color }}
            to={props.url}
        > 
            <span> {props.text} </span>
            <props.icon color='black' />
        </NavLink>
    )
  
}

export default ButtonIcon;