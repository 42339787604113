import React from 'react'
import { NavLink } from "react-router-dom";
import './buttons.css';

type Props = {
    color: string;
    text: string;
    url: string;
}

const ButtonBox = (props: Props) => {

    const colorClass: string = (props.color === 'black') ? 'button__box-black' : 'button__box-white';

    return (
        <NavLink className={'button__all button__box b1 ' + colorClass} to={props.url}> {props.text} </NavLink>
    )
  
}

export default ButtonBox;