import React from 'react'
import { ButtonOutline, ButtonBox, BlockContact, BlockLanding } from '../../components';
import { Carousel } from "antd";
// Assets
import LandingImage from '../../assets/products_landing.jpg';
import ContactImage from '../../assets/contact_office.jpg';

// Styles
import '../page.css';
import './shop.css';

// All text on this page
let text = require('./text.json');

// Date-types for this component
type Props = {
  language: string;
}

// The Shop page component
const Shop = (props: Props) => {
  return (
    <>
    <BlockLanding
      title={text[props.language]["landing-title"]}
      image={LandingImage}
      content={<>
        <h2 className='h2 text-center text-white no_bottom'> {text[props.language]["landing-products-title"]} </h2>
        <Carousel autoplay draggable className='products__landing-products'>
          <div className='shop__landing-products_product'>
            <img src='' alt='Product 1' />
            <p className='p-bold2 text-white'>Product 1</p>
          </div>
          <div className='shop__landing-products_product'>
            <img src='' alt='Product 2' />
            <p className='p-bold2 text-white'>Product 2</p>
          </div>
          <div className='shop__landing-products_product'>
            <img src='' alt='Product 3' />
            <p className='p-bold2 text-white'>Product 3</p>
          </div>
      </Carousel>
      </>}
    />

    <div className='page__spacing' />

    <BlockContact
      title={text[props.language]["contact-title"]}
      text={text[props.language]["contact-text"]}
      image={ContactImage}
      buttons={<>
        <ButtonBox color='white' text={text[props.language]["contact-button-contact"]} url='/contact' />
        <ButtonOutline color='white' text={text[props.language]["contact-button-request"]} url='/contact#form?brochure' />
      </>}
    />
    </>
  )
}

export default Shop;