import React from 'react'

// Date-types for this component
type Props = {
  text: { [key: string]: any }
}

// The Reviews page component
const Reviews = (props: Props) => {
  const text: { [key: string]: any } = props.text;
  
  return (
    <>
    <div className='home__reviews blocks__container'>
      <p className='p-bold1 text-center half-width'>“{text["reviews-text"]}”</p>
      {/* TODO: Add a customer here!
       <div className='home__reviews-image'></div>
      <h3 className='p-bold2 text-center'>{text["reviews-customer-name"]}</h3>
      <p className='p2 text-center'>{text["reviews-customer-role"]}</p> */}
    </div>
    </>
  )
}

export default Reviews;