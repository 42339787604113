import React, { useRef } from 'react';
import './upload.css';
import '../buttons/buttons.css';

const UpdateBrochure: React.FC = () => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (fileInput.current?.files && fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append('file', fileInput.current.files[0]);

      const url = process.env.REACT_APP_BACKEND_PHP! + 'BROCHURE/';
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
          credentials: 'include', // This sends session cookies
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Success:', JSON.stringify(result));
        // Handle success, e.g., show a success message or redirect

      } catch (error) {
        console.error('Error:', error);
        // Handle error, perhaps show an error message to the user
      }
    }
  };

  return (
    
    <form className='upload_brochure' onSubmit={handleSubmit}>
      <h1 className='p-bold1'>Ladda upp ny broschyr</h1>
      <input 
        className="custom-file-input"
        type="file" 
        accept=".pdf" 
        ref={fileInput} 
        required 
      />
      <br />
      <button className='button__all button__box button__box-black' type="submit">Ladda Upp</button>
    </form>
  );
};

export default UpdateBrochure;