import React from 'react'
import './blocks.css';

type Props = {
  slogan: string; // A small slogan at the top
  defaultKey: string;
  title: string;
  text: string;
  highlight_titles: string[];
  highlight_texts: string[];
  highlight_images: string[];
  filled: boolean;
  buttons: React.ReactNode; // A section containing buttons
}

type colorStyle = { color: string; } | { color?: undefined; };

/**
 * @brief Generates all highlights for the Highlight component
 * @param key A unique react key for all highlights
 * @param titles The title of each individual highlight
 * @param texts The text of each individual highlight
 * @param images The image of each individual highlight
 * @returns Multiple components containing all high lights
 */
function generateHighlights(key: string, titles: string[], texts: string[], images: string[], filled: colorStyle): React.ReactNode {
  const html = [];
  for (let i: number = 0; i < titles.length; i++) {
    html.push( singleHighlight(key + i, images[i], titles[i], texts[i], filled) );
  }
  return (<>{html}</>);
}

/**
 * @brief The component of one single highlight within a row of multiple highlights
 * @param key A unique react key for this component
 * @param image 
 * @param title 
 * @param text 
 * @returns One highlight within a row of highlights
 */
const singleHighlight = (key: string, image: string, title: string, text: string, filled: colorStyle): React.ReactNode  => (
  <div key={key + "_contents"} className='blocks__highlight-contents_highlight'>
    <div key={key + "_image_div"} className='blocks__highlight-contents_highlight-image'>
      <img key={key + "_image"} src={image} alt='Highlight' />
    </div>
    <h3 key={key + "_title"} className='p-bold2' style={filled}> {title} </h3>
    <p key={key + "_text"} className='p2' style={filled}> {text} </p>
  </div>
)

/**
 * @brief Building block contains two horizontal parts, one with an image and one with text and buttons
 * @param props 
 * @returns HTML
 */
const Highlights = (props: Props) => {
  const filledBackground = (props.filled) ? {'background': 'var(--infra-blue)'} : {};
  const filledColor = (props.filled) ? {'color': 'var(--infra-white)'} : {};

  return (
    <div className='blocks__highlight blocks__container' style={filledBackground}>
      <p className='p2' style={filledColor} > { props.slogan } </p>
      <h2 className='h2 half-width' style={filledColor}> { props.title } </h2>
      <p className='p2 half-width' style={filledColor}> { props.text } </p>
      <div className='blocks__highlight-contents'>
        { generateHighlights(props.defaultKey, props.highlight_titles, props.highlight_texts, props.highlight_images, filledColor) }
      </div>
      <div className='page__button-container-horizontal'>
          { props.buttons }
      </div>
    </div>
  )
}

export default Highlights;