import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import UpdateBrochure from './UpdateBrochure';
import './admin.css';
import '../buttons/buttons.css';

const Admin = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>("");
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const loggedInVal = sessionStorage.getItem("loggedIn");
        const username = sessionStorage.getItem("username") ?? "Error";
        const realname = sessionStorage.getItem("realname") ?? "Error";
        
        if (!loggedInVal || loggedInVal !== "true") {
            navigate("/login");
        } else {
            setLoggedIn(true);
            setName(realname); // Set username from storage
        }
    }, [navigate]);

    function handleLogout() {
        const username = sessionStorage.getItem("username") ?? "Error";
        const data = {username: username};
        
        const url = process.env.REACT_APP_BACKEND_PHP! + 'LOGOUT/';
        fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log("Logged out!");
            return response.json(); // If your server sends back any JSON response, parse it here
        })
        .then(data => {
            // Handle the response data if needed. Here, we're just logging out.
        })
        .catch(error => {
            console.log("Error logging out: " + error);
        })
        .finally(() => {
            sessionStorage.clear();
            navigate("/login");
        });
    }

    return (
        <>
        {loggedIn && (
            <div className="admin__main">
                <div className="admin__main-topbar">
                    <h1 className='h3'>Administration</h1>
                    <h1 className='p-bold1'>Inloggad: {name}</h1>
                    <div className='admin__main-topbar__buttons'>
                        <button className='button__all button__box button__box-white' onClick={handleLogout}> Logga Ut </button>
                    </div>
                </div>
                <div className="admin__main-body">
                    <div className='admin__main-body-row'>
                        <div className='admin__main-body-item'>
                            <UpdateBrochure />
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );
}

export default Admin;