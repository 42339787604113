import React from 'react'
import { ButtonBox, ButtonOutline, BlockLanding } from '../../components';
import ContactForm from './ContactForm';
import Employees from './Employees';
import Visit from './Visit';
import LandingImage from '../../assets/contact_landing.jpg';
import '../page.css';
import './contact.css';

// Date-types for this component
type Props = {
  language: string;
  text: { [key: string]: any }
}

// The Contact page component
const Contact = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
    
    <BlockLanding
      title={text["landing-title"]}
      image={LandingImage}
      content={<>
        <p className='p3 text-center text-white'> {text["landing-text"]} </p>
        <br />
        <div className='page__button-container-horizontal'>
          <ButtonBox color='white' text={text["landing-button-visit"]} url='#visit' />
          <ButtonOutline color='white' text={text["landing-button-contact"]} url='#form' />
        </div>
      </>}
    />
    <div id="visit" />

    <div className='page__spacing' />

    {/* TODO: Lägg tillbaka sen! 
    
    <Employees text={props.text} />

    <div className='page__spacing-double' id="visit" /> */}

    <Visit text={props.text} />

    <div className='page__spacing-double' id='form' />

    <ContactForm language={props.language} text={props.text} />


    </>
  )
}

export default Contact;