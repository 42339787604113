import React from 'react'
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';

type Props = {
    language: string;
    onSetLanguage: (choice: string) => void;
}


const LanguageSelection = (props: Props) => {
  
  // All menu alternatives
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span onClick={() => props.onSetLanguage('en')}>
          English
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={() => props.onSetLanguage('sv')}>
          Svenska
        </span>
      )
    },
  ];

  return (
    <Dropdown menu={{ items }} overlayClassName="header__dropdown">
        <Space className='header__language-button b1'>
          { (props.language === 'en') ? 'English' : 'Svenska' }
          <DownOutlined />
        </Space>
    </Dropdown>
  );
}

export default LanguageSelection;