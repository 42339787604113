import React from 'react'
import './privacy.css';

type Props = {
  text: { [key: string]: any }
}

const Privacy = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
      <div className='page__spacing' />

      <div className='privacy blocks__container'>
        <h1 className='h2 text-center'> {text["privacy"]} </h1>
        <p className='p2 half-width'>
          <span> {text["privacy_introduction"]} </span>
          <span> {text["privacy_who-we-are"]} </span>
          <span> {text["privacy_what-we-collect"]} </span>
        </p>
        <p className='p2 half-width'>
          <span> {text["privacy_how-we-use"]} </span>
          <span> {text["privacy_legal-sharing"]} </span>
        </p>
        <p className='p2 half-width'>
          <span> {text["privacy_data-retention"]} </span>
          <span> {text["privacy_your-rights"]} </span>
          <span> {text["privacy_security"]} </span>
          <span> {text["privacy_contact"]} </span>
        </p>
      </div>
      
      <div className='page__spacing' />
    </>
  )
}

export default Privacy;