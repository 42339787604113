import React from 'react'
import { ButtonBox, ButtonOutline, BlockContact, BlockTextAndImage, BlockLanding, BlockHighlights } from '../../components';
import FAQ from './FAQ';
// Assets
import LandingImage from '../../assets/about_landing.jpg';
import AboutEffective from '../../assets/about_effective.jpg';
import AboutVision from '../../assets/about_vision.jpeg';
import AboutClimate from '../../assets/about_climate.jpeg';
import AboutDurable from '../../assets/about_durable.jpg';
import StorageImage from '../../assets/storage2.jpg';
import ContactImage from '../../assets/contact_office.jpg';
// Styles
import '../page.css';
import './about.css';

// Date-types for this component
type Props = {
  text: { [key: string]: any };
}

// The About page component
const About = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
      <BlockLanding
        title={text["landing-title"]}
        image={LandingImage}
        content={<>
          <p className='p3 text-center text-white'> {text["landing-text"]} </p>
          <br />
          <div className='page__button-container-horizontal'>
            <ButtonBox color='white' text={text["landing-button-why-us"]} url='#highlight' />
            <ButtonOutline color='white' text={text["landing-button-faq"]} url='#vision' />
          </div>
        </>}
      />

    <div className='page__spacing' />

    <BlockTextAndImage 
      imageSide='right'
      slogan={text["about-slogan"]}
      title={text["about-title"]}
      text={text["about-text"]}
      image={AboutEffective}
      filled={false}
      buttons={<>
        <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
          <ButtonBox color='black' text={text["about-button-contact"]} url='/contact' />
        </div>
      </>}
    />

    <div className='page__spacing-double' id='highlight' />

    <BlockHighlights
      defaultKey='home_highlight'
      slogan={text["why-slogan"]}
      title={text["why-title"]}
      text={text["why-text"]}
      
      highlight_images={[AboutClimate, AboutDurable, StorageImage]}
      highlight_titles={[
        text["why-1-title"],
        text["why-2-title"],
        text["why-3-title"]
      ]}
      highlight_texts={[
        text["why-1-text"],
        text["why-2-text"],
        text["why-3-text"]
      ]}
      filled={true}
      buttons={<></>}
    />

<div className='page__spacing-double' id='vision' />

    <BlockTextAndImage 
      imageSide='left'
      slogan={text["vision-slogan"]}
      title={text["vision-title"]}
      text={text["vision-text"]}
      image={AboutVision}
      filled={false}
      buttons={<>
      <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
        <ButtonBox color='black' text={text["vision-button-contact"]} url='/contact' />
      </div>

      </>}
    />
{/* 
    <div className='page__spacing-double' id='faq' />

    <FAQ language={props.language} /> */}

    <div className='page__spacing-double' />

      <BlockContact
        title={text["contact-title"]}
        text={text["contact-text"]}
        image={ContactImage}
        buttons={<>
          <ButtonBox color='white' text={text["contact-button-contact"]} url='/contact' />
          <ButtonOutline color='white' text={text["contact-button-request"]} url='/products' />
        </>}
      />

    </>
  )
}

export default About;