import React from 'react'
import { ButtonBox, ButtonOutline, ButtonIcon, BlockTextAndImage, BlockContact, BlockLanding, BlockHighlights } from '../../components';
import Reviews from './Reviews';
// Assets
import { AiOutlineRight } from "react-icons/ai";
import LandingImage from '../../assets/home_landing.jpg';
import TunnelImage from '../../assets/home_tunnel.jpeg';
import NatureImage from '../../assets/home_nature.jpeg';
import DurableImage from '../../assets/home_durable.jpg';
import StorageImage from '../../assets/home_storage.jpg';
import ContactImage from '../../assets/contact_office.jpg';

// Styles
import '../page.css';
import './home.css';

// Date-types for this component
type Props = {
  text: { [key: string]: any }
}

// The Home page component
const Home = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
    <BlockLanding
      title={text["landing-title"]}
      image={LandingImage}
      content={<>
        <p className='p3 text-center text-white'> {text["landing-text"]} </p>
        <br />
        <div className='page__button-container-horizontal'>
          <ButtonBox color='white' text={text["landing-button-explore"]} url='#explore' />
          <ButtonOutline color='white' text={text["landing-button-more"]} url='contact#form' />
        </div>
      </>}
    />

    <div className='page__spacing' />

    <div id="explore" />
    <BlockTextAndImage 
      imageSide='right'
      slogan={text["illuminate-slogan"]}
      title={text["illuminate-title"]}
      text={text["illuminate-text"]}
      image={TunnelImage}
      filled={false}
      buttons={<>
        <ButtonOutline color='black' text={text["illuminate-button-explore"]} url='about' />
        <ButtonIcon icon={AiOutlineRight} color='black' text={text["illuminate-button-contact"]} url='contact' />
      </>}
    />

    <div className='page__spacing-double' />

    <BlockHighlights
      defaultKey='home_highlight'
      slogan={text["efficient-slogan"]}
      title={text["efficient-title"]}
      text={text["efficient-text"]}
      
      highlight_images={[NatureImage, DurableImage, StorageImage]}
      highlight_titles={[
        text["efficient-1-title"],
        text["efficient-2-title"],
        text["efficient-3-title"]
      ]}
      highlight_texts={[
        text["efficient-1-text"],
        text["efficient-2-text"],
        text["efficient-3-text"]
      ]}
      filled={true}
      buttons={<>
        <ButtonOutline color='white' text={text["efficient-button-products"]} url='products' />
        <ButtonIcon icon={AiOutlineRight} color='black' text={text["efficient-button-contact"]} url='contact' />
      </>}
    />

    <div className='page__spacing-double' />

    <Reviews text={props.text} />

    <div className='page__spacing-double' />

    <BlockContact
      title={text["contact-title"]}
      text={text["contact-text"]}
      image={ContactImage}
      buttons={<>
        <ButtonBox color='white' text={text["contact-button-contact"]} url='contact' />
        <ButtonOutline color='white' text={text["contact-button-request"]} url='products' />
      </>}
    />

    </>
  )
}

export default Home;