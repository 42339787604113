import React, { ElementType } from 'react'
import './blocks.css';

type Props = {
  title: string;
  text: string;
  image: string;
  buttons: React.ReactNode; // A section containing buttons
}

/**
 * @brief Building block contains two horizontal parts, one with an image and one with text and buttons
 * @param props 
 * @returns HTML
 */
const Contact = (props: Props) => {
  return (
    <div className='blocks__contact'>
      <div className='blocks__contact-below'>
        <div className='blocks__container'>
          <div className='blocks__contact-below_content'>
            <h2 className='h2'>{props.title}</h2>
            <p className='p2'>{props.text}</p>
            <div className='page__button-container-horizontal'>
              {props.buttons}
            </div>
          </div>
        </div>
        </div>
        <div className='blocks__container'>
        <div className='blocks__contact-above'>
          <img src={props.image} alt="contact image" />
        </div>
      </div>
    </div>
  )
}

export default Contact;