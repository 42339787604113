import React from 'react'
import { ButtonOutline, ButtonIcon, ButtonBox, BlockContact, BlockTextAndImage, BlockLanding, BlockHighlights } from '../../components';
import { Carousel } from "antd";
// Assets
import { AiOutlineRight } from "react-icons/ai";
import LandingImage from '../../assets/products_landing.jpg';

import ProdArmaImage from '../../assets/prod_arma.jpg';
import ProdKabelImage from '../../assets/prod_kabel.jpg';
import ProdFundImage from '../../assets/prod_fund.jpg';
import ProdStolpImage from '../../assets/prod_stolp.jpg';
import ProdLjusImage from '../../assets/prod_ljus.jpg';
import ProdSkapImage from '../../assets/prod_skap.jpg';
import ProdSakrImage from '../../assets/prod_sakr.jpg';
import ProdVerkImage from '../../assets/prod_verktyg.jpg';
import ProdSafeImage from '../../assets/prod_safety.jpg';
import ProdOtherImage from '../../assets/prod_other.jpg';

import TjansterImage from '../../assets/tjanster.jpg';
import SpecialImage from '../../assets/special.jpg';
import ContactImage from '../../assets/contact_office.jpg';

// Styles
import '../page.css';
import './products.css';
import Brochure from '../../components/brochure/Brochure';

// Date-types for this component
type Props = {
  text: { [key: string]: any }
}

// The Products page component
const Products = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
    <BlockLanding
      title={text["landing-title"]}
      image={LandingImage}
      content={<>
        <Carousel autoplay draggable className='products__landing-products'>
          <div className='products__landing-products_product'>
            <img src={ProdArmaImage} alt='Product 1' />
            <p className='p-bold2 text-white'> {text["offers-1-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdStolpImage} alt='Product 2' />
            <p className='p-bold2 text-white'> {text["offers-2-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdFundImage} alt='Product 3' />
            <p className='p-bold2 text-white'> {text["offers-3-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdLjusImage} alt='Product 4' />
            <p className='p-bold2 text-white'> {text["offers-4-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdKabelImage} alt='Product 5' />
            <p className='p-bold2 text-white'> {text["offers-5-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdSkapImage} alt='Product 6' />
            <p className='p-bold2 text-white'> {text["offers-6-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdSakrImage} alt='Product 7' />
            <p className='p-bold2 text-white'> {text["offers-7-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdVerkImage} alt='Product 8' />
            <p className='p-bold2 text-white'> {text["offers-8-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdSafeImage} alt='Product 9' />
            <p className='p-bold2 text-white'> {text["offers-9-title"]} </p>
          </div>
          <div className='products__landing-products_product'>
            <img src={ProdOtherImage} alt='Product 10' />
            <p className='p-bold2 text-white'> {text["offers-10-title"]} </p>
          </div>
      </Carousel>
      {/* TODO: Lägg till denna brochyr när detta är klart!
      <Brochure text={text['offers-brochure']} /> */}

      </>}
    />

    <div className='page__spacing' />

    <BlockHighlights
      defaultKey='products_highlight'
      slogan={text["offers-slogan"]}
      title={text["offers-title"]}
      text={text["offers-text"]}
      
      highlight_images={[
        ProdArmaImage, ProdStolpImage, ProdFundImage, ProdLjusImage, ProdKabelImage, 
        ProdSkapImage, ProdSakrImage, ProdVerkImage, ProdSafeImage, ProdOtherImage]}
      highlight_titles={[
        text["offers-1-title"],
        text["offers-2-title"],
        text["offers-3-title"],
        text["offers-4-title"],
        text["offers-5-title"],
        text["offers-6-title"],
        text["offers-7-title"],
        text["offers-8-title"],
        text["offers-9-title"],
        text["offers-10-title"]
      ]}
      highlight_texts={[
        text["offers-1-text"],
        text["offers-2-text"],
        text["offers-3-text"],
        text["offers-4-text"],
        text["offers-5-text"],
        text["offers-6-text"],
        text["offers-7-text"],
        text["offers-8-text"],
        text["offers-9-text"],
        text["offers-10-text"]
      ]}
      filled={false}
      buttons={<>
        <ButtonOutline color='black' text={text['offers-brochure']} url='#' />
        <ButtonIcon icon={AiOutlineRight} color='black' text={text["offers-button-contact"]} url='/contact' />
      </>}
      />

      <div className='page__spacing-double' />

      <BlockTextAndImage 
        imageSide='right'
        slogan={text["services-slogan"]}
        title={text["services-title"]}
        text={text["services-text"]}
        image={TjansterImage}
        filled={true}
        buttons={<>
          <ButtonOutline color='white' text={text["services-button-request"]} url='/contact#form?service' />
          <ButtonIcon icon={AiOutlineRight} color='black' text={text["services-button-questions"]} url='/contact#form' />
        </>}
      />

      <div className='page__spacing-double' />

      <BlockTextAndImage 
        imageSide='left'
        slogan={text["custom-slogan"]}
        title={text["custom-title"]}
        text={text["custom-text"]}
        image={SpecialImage}
        filled={false}
        buttons={<>
          <div style={{'width': '100%', 'marginTop': '1rem'}} className='force-button-left'>
            <ButtonOutline color='black' text={text["custom-button-request"]} url='/contact#form?customization' />
          </div>
        </>}
      />

      <div className='page__spacing-double' />

      <BlockContact
        title={text["contact-title"]}
        text={text["contact-text"]}
        image={ContactImage}
        buttons={<>
          <ButtonBox color='white' text={text["contact-button-contact"]} url='/contact' />
          <ButtonOutline color='white' text={text['offers-brochure']} url='#' />
        </>}
      />
    </>
  )
}

export default Products;