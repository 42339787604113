import React from 'react'
import { NavLink } from "react-router-dom";
import './buttons.css';

type Props = {
    color: string;
    text: string;
    url: string;
}

const ButtonOutline = (props: Props) => {

    return (
        <NavLink 
            className={'button__all button__outline b1'} 
            to={props.url}
            style={{ color: props.color, borderColor: props.color }}
        > 
            <span>{props.text}</span> 
        </NavLink>
    )
  
}

export default ButtonOutline;