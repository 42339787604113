import React, { useState } from 'react'
import Navigation from '../navigation/Navigation';
import { AiOutlineRight } from "react-icons/ai";
import './sidebar.css';

type Props = {
  active: boolean;
  text: { [key: string]: any };
}

const Sidebar = (props: Props) => {
  let sidebarClass = "sidebar";
  if (props.active) {
    sidebarClass += " sidebar__opened"
  } else {
    sidebarClass += " sidebar__closed"
  }

  return ( 
    <div className={sidebarClass}>
      <div className='sidebar__nav b1'>
        <Navigation text={props.text["navigation"]} />
      </div>
      <div className='sidebar__right'>
        <div className='sidebar__right-wrapper'>
          <AiOutlineRight color='white' size='28' />
        </div>
        <div className='sidebar__right-wrapper'>
          <AiOutlineRight color='white' size='28' />
        </div>
        <div className='sidebar__right-wrapper'>
          <AiOutlineRight color='white' size='28' />
        </div>
        <div className='sidebar__right-wrapper'>
          <AiOutlineRight color='white' size='28' />
        </div>
      </div>
    </div>
  )
}

export default Sidebar;