import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Select,
  message
} from 'antd';
import { SecureButton } from '../../components';
import './contactForm.css';

let errandTypes = require('./errandTypes.json');

// Date-types for this component
type Props = {
  language: string;
  text: { [key: string]: any }
}

/**
 * @brief Get the default errand value from a '?example' part of a string
 * @param hash The hash section of an URL
 * @returns A fetched errand of the default one
 */
function getDefaultErrand (hash: string): string {
  let defaultValue = 'other';
  if (hash) {
    const id = hash.substring(1)
    if (id !== undefined && id !== '') {
      defaultValue = id.split('?')[1];
    }
  }
  return defaultValue;
}

// The ContactForm page component
const ContactForm = (props: Props) => {
  const text: { [key: string]: any } = props.text;
  
  const [haveAccepted, setAccepted] = useState(false);
  const [sendTime, setSendTime] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const { hash } = useLocation();
  
  const [form] = Form.useForm();
  const [messageApi, sendMessageContextHolder] = message.useMessage();

  // A message shown to the user if successfully sending a message
  const successMessage = (name: string) => {
    const message = text["contact-form-message-success"];
    // Puts the sender name inside the message
    const fixedMessage = message.replace('${name}', name);

    messageApi.open({
      type: 'success',
      content: fixedMessage,
    });
  };

  // A message shown to the user if failing to send a message
  const errorMessage = () => {
    const message = text["contact-form-message-failure"];

    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  // When the user pressed the submit button
  const onFinish = async (values: any) => {
    if (!haveAccepted) return; // The user must accept the terms
    if ((Date.now() - sendTime) < 3000) return; // There must be at least 3 seconds between each sent message
    
    setLoading(true); // This is due to an animated loading icon
    const url = process.env.REACT_APP_PHP_CONTACTFORM!;

    try {
      // Create a new FormData object to send the data
      const formData = new FormData();
      formData.append('Errand', values.Errand);
      formData.append('Name', values.Name);
      formData.append('Company', values.Company);
      formData.append('Mail', values.Mail);
      formData.append('City', values.City);
      formData.append('Message', values.Message);
      
      // Send a POST request to a PHP program
      await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: formData
      })
      .then(response => {
        if (response.status === 200) {
          successMessage(values.Name);
        } else {
          errorMessage();
          console.log(response.status);
        }
        formComplete();
      })
      .catch(error => {
        console.error('There was an error submitting the form:', error);
        errorMessage();
        formComplete();
      })

    } catch (error) {
      console.log(error);
    }
  }

  // When someone has completed the form
  function formComplete() {
    setLoading(false);
    form.resetFields();
    setSendTime(Date.now());
  }

  // If the form failed to complete
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    {sendMessageContextHolder}
    <div className='contact__form'>
      <div className='contact__form-below'>
        <div className='blocks__container'>
          <div className='contact__form-below_content'>
            <h2 className='h2'>{ text["contact-title"] }</h2>
            <p className='p2'>{ text["contact-text"] }</p>
          </div>
        </div>
      </div>
      <div className='blocks__container'>
        <div className='contact__form-above'>
          <h3 className='p-bold3'>{ text["contact-form-title"] }</h3>
          <Form 
            className='contact__form-above_form' 
            layout="vertical"
            form={form}
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed}
            initialValues={{
              Errand: getDefaultErrand(hash) // Set initial value for 'Errand' field
            }}
          >
            <Form.Item 
              label={text["contact-form-errand"]}
              name="Errand" 
              rules={[
                { 
                  required: false
                }
              ]}>
              <Select options={errandTypes[props.language]} />
            </Form.Item>

            <Form.Item 
              label={text["contact-form-name"]}
              name="Name" 
              rules={[
                { 
                  required: true, 
                  message: text["contact-form-error-required"] 
                },
                {
                  pattern: /^[a-zA-ZÅåÄäÖöÈèÜüßẞ\- ]+$/,
                  message: text["contact-form-error-only-letters"]
                }
              ]}>
              <Input placeholder={text["contact-form-name-placeholder"]} />
            </Form.Item>

            <Form.Item 
              label={text["contact-form-company"]}
              name="Company" 
              rules={[
                { 
                  required: true, 
                  message: text["contact-form-error-required"] 
                },
                {
                  pattern: /^[0-9a-zA-ZÅåÄäÖöÈèÜüßẞ\- ]+$/,
                  message: text["contact-form-error-only-letters"]
                }
              ]}>
              <Input placeholder={text["contact-form-company-placeholder"]} />
            </Form.Item>

            <Form.Item 
              label={text["contact-form-mail"]}
              name="Mail" 
              rules={[
                { 
                  required: true, 
                  message: text["contact-form-error-required"] 
                },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: text["contact-form-error-only-mail"]
                }
              ]}>
              <Input placeholder={text["contact-form-mail-placeholder"]} />
            </Form.Item>

            <Form.Item 
              label={text["contact-form-city"]}
              name="City" 
              rules={[
                { 
                  required: false, 
                },
                {
                  pattern: /^[a-zA-ZÅåÄäÖöÈèÜüßẞ\- ]+$/,
                  message: text["contact-form-error-only-letters"]
                }
              ]}>
              <Input placeholder={text["contact-form-city-placeholder"]} />
            </Form.Item>

            <Form.Item
              label={text["contact-form-message"]}
              name="Message"
              rules={[
                { 
                  required: true, 
                  message: text["contact-form-error-required"] 
                },
                {
                  pattern: /^[0-9,.@'\n\-%/ a-zA-ZÅåÄäÖöÈèÜüßẞ]+$/,
                  message: text["contact-form-error-only-text"]
                },
                {
                  min: 20,
                  message: text["contact-form-error-minimum"]
                }
              ]}>
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder={text["contact-form-message-placeholder"]} />
            </Form.Item>

            <div className='contact__form-above_form-lower'>
              <p className='p4'> { text["contact-form-agreement"] } </p>
              <div className='contact__form-above_form-buttons'>
                <SecureButton onAccept={(value) => setAccepted(value)} color='black' language={props.language} />
                <Form.Item>
                  <Button htmlType="submit" loading={loading} >
                    { text["contact-form-send"] }
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          
        </div>

      </div>
    </div>
    </>
  )
}

export default ContactForm;