import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import LandingBackground from '../../../assets/home_landing.jpg'; 
import './login.css';

const BETWEEN_LOGIN_TIME = 2000; // 2 seconds between each try

const Login = () => {
    const navigate = useNavigate();
    const [failed, setFailed] = useState(false);
    const [loginTime, setLoginTime] = useState(Date.now());

    const onFinish = (values: any) => {
        if ((Date.now() - loginTime) < BETWEEN_LOGIN_TIME) return;
    
        const data = JSON.stringify(values);
    
        const url = process.env.REACT_APP_BACKEND_PHP! + 'LOGIN/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: data
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}, message: ${response.statusText}`);
            }
            return response.json(); // Assuming your server responds with JSON
        })
        .then(data => {
            sessionStorage.setItem("loggedIn", 'true');
            sessionStorage.setItem("username", JSON.stringify(values.name));
            sessionStorage.setItem("realname", JSON.stringify(data.name));
    
            navigate('/admin');
        })
        .catch(error => {
            setLoginTime(Date.now());
            onFinishFailed("failed: " + error.message);
        });
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        setFailed(true);
    };

    return (
        <>
        <div className='login__body-image' style={{backgroundImage: `url(${LandingBackground})`}}>
            <div className='login__body-overlay'>
                <h1>Logga in</h1>
                {failed && (<p>Fel användarnamn eller lösenord!</p>)}
                <div>
                <Form
                    name="basic"
                    labelCol={{
                    span: 10,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    style={{
                    maxWidth: 666,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Användarnamn"
                        name="name"
                        rules={[
                            {pattern: new RegExp(/^[a-zA-Z0-9_]{6,20}$/),message: "Minst sex tecken!"},
                            {
                            required: true,
                            message: 'Var god fyll i ditt användarnamn!',
                            },
                        ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Lösenord"
                        name="password"
                        rules={[
                            {pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),message: "Minst 8 tecken, stor/liten bokstav, siffra samt tecken!"},
                            {
                            required: true,
                            message: 'Var god fyll i ditt lösenord!',
                            },
                        ]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <div className='login__buttons'>
                        <button className='button__all button__box button__box-white'>
                            Logga in
                        </button>
                    </div>

                </Form>
                </div>

            </div>
        </div>
        </>
    );
}


export default Login;