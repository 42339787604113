import React from 'react'
import { NavLink } from "react-router-dom";


type Props = {
  text: { [key: string]: any };
}

const Navigation = (props: Props) => {
  const text: { [key: string]: any } = props.text;

  return (
    <>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/"
      >
        {text["home"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/products"
      >
        {text["products"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/about"
      >
        {text["about"]}
      </NavLink>
      <NavLink 
        className={({ isActive }) => isActive ? "b1 active" : "b1"} 
        to="/contact"
      >
        {text["contact"]}
      </NavLink>
    </>
  )
}

export default Navigation;