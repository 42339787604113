import React, { useState } from 'react';
import ButtonOutline from '../buttons/ButtonOutline';
import {getCookie, setCookie} from '../../functionality/cookies';
import './consent.css';
import '../buttons/buttons.css'

type Props = {
  text: { [key: string]: any }
}

const Consent = (props: Props) => {
  const text: { [key: string]: any } = props.text;
  
  const [open, setOpen] = useState((getCookie('cookie-accept') === '') ? true : false);

  const onClose = () => {
    setOpen(false);
  };

  const onAccept = () => {
    setCookie('cookie-accept', 'true', 360);
    onClose();
  }

  const onReject = () => {
    setCookie('cookie-accept', 'false', 360);
    onClose();
  }

  return (
    <div className={'consent ' + (!open && 'consent__closed')}>
        <h1 className='h4 text-center'> {text['title']} </h1>
        <p className='p4 text-center half-width'> {text['text']} </p>
        <div className='consent__buttons'>
          <button className='button__box consent_buttons-button b2' onClick={onAccept}> {text['button-accept']} </button>
          <button className='button__box consent_buttons-button b2' onClick={onReject}> {text['button-reject']} </button>
          <ButtonOutline url='/cookies' text={text['button-read']} color='white' />
        </div>
    </div>
  );
};

export default Consent;