import React, { useState, useEffect, ChangeEvent } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './secureButton.css';

interface SecureButtonProps {
    onAccept: (checked: boolean) => void;
    color: string;
    language: string;
}

const SecureButton: React.FC<SecureButtonProps> = ({ onAccept, color, language }) => {
    const [buttonEnabled, setButtonEnabled] = useState(false);

    const buttonText = language === 'sv' 
        ? "Godkänn"
        : "Approve";

    const handleClick = (e: CheckboxChangeEvent) => {
        if (buttonEnabled) onAccept(e.target.checked);
    }

    useEffect(() => {
        const handleInteraction = () => {
            setButtonEnabled(true);
            document.removeEventListener('mousemove', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
        };

        document.addEventListener('mousemove', handleInteraction);
        document.addEventListener('touchmove', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);

        return () => {
            document.removeEventListener('mousemove', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
        };
    }, []);

    return (
        <div className='secure-button p4'>
            <Checkbox 
                style={{ color: color }} 
                defaultChecked={false}
                onChange={handleClick}
            >
                <span className='p4'>{buttonText}</span>
            </Checkbox>
        </div>
    );
}

export default SecureButton;